import {
  APISarValues,
  FederalRegulators,
  FilingInstitutionTypes,
  FinancialInstitutionTypes,
  FinancialInstitutionWhereActivityOccurred,
  Subject,
  SubjectIdTypes,
} from 'app/modules/fincenSarNew/models';
import { ReportType, SarStatus } from 'app/modules/fincenSar/models';
import { FullSARFilingResponse } from 'app/modules/fincenSarNew/response';
import {
  COUNTRY_OPTIONS,
  getStateOptions,
} from 'app/shared/constants/locations';
import { TableConfig } from 'app/shared/pagination/models';

export const SAR_FORM_ID = 'SAR_FORM_ID';

export const DEFAULT_FILING_NAME = 'FinCEN SAR Filing';

export const INITIAL_SAR_CONTENT: APISarValues = {
  '0': {
    content: {
      '1_typeOfFiling': {
        'Continuing activity report': false,
        'Correct/Amend prior report': false,
        'Initial report': false,
        'Joint report': false,
      },
      '2_filingInstitutionNote': '',
      filingName: '',
      priorBsaId: '',
    },
    valid: false,
  },
  '1': {
    content: {
      '75_primaryFederalRegulator': '',
      '76_filerName': '',
      '77_taxpayerIdentificationNumber': '',
      '78_taxpayerIdentificationType': '',
      '79_typeOfFinancialInstitution': '',
      '79_typeOfFinancialInstitutionOther': '',
      '80_typeOfSecuritiesAndFutures': {
        'CPO/CTA': false,
        'Clearing broker-securities': false,
        'Execution-only broker securities': false,
        'Futures Commission Merchant': false,
        'Holding company': false,
        'Introducing broker-commodities': false,
        'Introducing broker-securities': false,
        'Investment Adviser': false,
        'Investment company': false,
        Other: false,
        'Retail foreign exchange dealer': false,
        'SRO Futures': false,
        'SRO Securities': false,
        'Self-clearing broker securities': false,
        'Subsidiary of financial/bank': false,
      },
      '80_typeOfSecuritiesAndFuturesOther': '',
      '81_financialInstitutionIdentificationNumber': '',
      '81_financialInstitutionIdentificationType': '',
      '82_address': '',
      '83_city': '',
      '84_state': '',
      '85_zip': '',
      '86_country': '',
      '87_alternateName': '',
      '88_internalControlFileNumber': '',
      '89_leContactAgency': '',
      '90_leContactName': '',
      '91_leContactPhoneNumber': '',
      '92_leContactDate': '',
      '93_filingInstitutionContactOffice': '',
      '94_filingInstitutionContactPhone': '',
      '95_dateFiled': '',
    },
    valid: false,
  },
  '2': {
    content: {
      forms: [
        {
          '51_typeOfFinancialInstitution': '',
          '51_typeOfFinancialInstitutionOther': '',
          '52_primaryFederalRegulator': '',
          '53_typeOfGamingInstitution': {
            'Card club': false,
            Other: false,
            'State licensed casino': false,
            'Tribal authorized casino': false,
          },
          '53_typeOfGamingInstitutionOther': '',
          '54_typeOfSecuritiesAndFutures': {
            'Clearing broker-securities': false,
            'Execution-only broker securities': false,
            'Futures Commission Merchant': false,
            'Holding Company': false,
            'Introducing broker-commodities': false,
            'Introducing broker-securities': false,
            'Investment adviser': false,
            'Investment company': false,
            Other: false,
            'Retail foreign exchange dealer': false,
            'Self-clearing broker-securities': false,
            'Subsidiary of financial/bank': false,
          },
          '54_typeOfSecuritiesAndFuturesOther': '',
          '55_financialInstitutionIdentificationNumber': '',
          '55_financialInstitutionIdentificationType': '',
          '56_financialInstitutionRoleInTransaction': {
            Both: false,
            'Paying location': false,
            'Selling location': false,
          },
          '57_financialInstitutionLegalName': '',
          '57_financialInstitutionLegalNameUnknown': false,
          '58_financialInstitutionAlternateName': '',
          '59_taxPayerIdentificationNumber': '',
          '59_taxPayerIdentificationNumberUnknown': false,
          '60_taxPayerIdentificationType': '',
          '61_address': '',
          '61_addressUnknown': false,
          '62_city': '',
          '62_cityUnknown': false,
          '63_state': '',
          '63_stateUnknown': false,
          '64_zip': '',
          '64_zipUnknown': false,
          '65_country': '',
          '65_countryUnknown': false,
          '66_internalControlFileNumber': '',
          '67_lossToFinancialInstitution': '',
          '68-74_branches': [
            {
              '68_roleInTransaction': {
                Both: false,
                'Paying location': false,
                'Selling location': false,
              },
              '69_address': '',
              '69a_noBranchActivityInvolvedIndicator': false,
              '70_rssdNumber': '',
              '71_city': '',
              '72_state': '',
              '73_zip': '',
              '74_country': '',
            },
          ],
        },
      ],
    },
    valid: false,
  },
  '3': {
    content: {
      forms: [
        {
          '3_entityInformationUnknown': {
            'All critical information unavailable (does not include item 24)':
              false,
            'If entity': false,
          },
          '4_legalName': '',
          '4_legalNameUnknown': false,
          '5_firstName': '',
          '5_firstNameUnknown': false,
          '6_middleName': '',
          '7_suffix': '',
          '8_gender': '',
          '9_alternativeName': [''],
          '10_occupation': '',
          '10a_naicsCode': '',
          '11-15_addressInformation': [
            {
              '11_address': '',
              '11_addressUnknown': false,
              '12_city': '',
              '12_cityUnknown': false,
              '13_state': '',
              '13_stateUnknown': false,
              '14_zipcode': '',
              '14_zipcodeUnknown': false,
              '15_country': '',
              '15_countryUnknown': false,
            },
          ],
          '16_taxPayerIdentificationNumber': '',
          '16_taxPayerIdentificationNumberUnknown': false,
          '17_taxPayerIdentificationType': '',
          '18_dateOfBirthUnknown': false,
          '18_formsOfId': [
            {
              '18_country': '',
              '18_idNumber': '',
              '18_idType': '',
              '18_state': '',
              '18z_OtherPartyIdentificationTypeText': '',
              disabled: false,
            },
          ],
          '19_dateOfBirth': '',
          '20-21_phoneNumberValues': [
            {
              '20_phoneNumberType': '',
              '21_phoneNumber': '',
            },
          ],
          '22_emailAddress': [''],
          '22a._websiteURL': [''],
          '23_statementToFiler': '',
          '24_relationships': [
            {
              '24_institutionTIN': '',
              '24_relationship': {
                Accountant: false,
                Agent: false,
                Appraiser: false,
                Attorney: false,
                Borrower: false,
                Customer: false,
                Director: false,
                Employee: false,
                Officer: false,
                Other: false,
                'Owner or Controlling Shareholder': false,
              },
              '24_relationshipOther': '',
              '25_status': '',
              '26_actionDate': '',
              disabled: false,
            },
          ],
          '27_accountNumbers': [
            {
              '27_accountNumbers': [
                {
                  '27_accountNumber': '',
                  '27_accountNumberClosed': false,
                },
              ],
              '27_tin': '',
              '27_tinUnknown': false,
              disabled: false,
            },
          ],
          '28_roleInActivity': '',
        },
      ],
    },
    valid: false,
  },
  '4': {
    content: {
      '29_amountInvolved': '',
      '29_typeOfAmountInvolved': {
        'Amount Unknown': false,
        'No Amount Involved': false,
      },
      '30_suspiciousActivityEndDate': '',
      '30_suspiciousActivityStartDate': '',
      '31_cumulativeAmount': '',
      '32_typeOfStructuring': {
        'Alters or cancels transaction to avoid BSA recordkeeping requirement':
          false,
        'Alters or cancels transaction to avoid CTR requirement': false,
        Other: false,
        'Suspicious inquiry by customer regarding  BSA reporting or recordkeeping requirements':
          false,
        'Transactions(s) below BSA recording threshold': false,
        'Transactions(s) below CTR threshold': false,
      },
      '32_typeOfStructuringOther': '',
      '33_typeOfTerroristFinancing': {
        'Known or suspected terrorist/terrorist organization': false,
        Other: false,
      },
      '33_typeOfTerroristFinancingOther': '',
      '34_typeOfFraud': {
        ACH: false,
        'Advance fee': false,
        'Business loan': false,
        Check: false,
        'Consumer loan': false,
        'Credit / Debit card': false,
        'Healthcare / Public or private health insurance': false,
        Mail: false,
        'Mass-marketing': false,
        Other: false,
        'Ponzi Scheme': false,
        'Pyramid Scheme': false,
        'Securities Fraud': false,
        Wire: false,
      },
      '34_typeOfFraudOther': '',
      '35_typeOfGamingActivities': {
        'Chip Walking': false,
        'Minimal Gaming with Large Transactions': false,
        Other: false,
        'Suspicious User of Counter Checks or Markers': false,
        'Unknown Source of Chips': false,
      },
      '35_typeOfGamingActivitiesOther': '',
      '36_typeOfMoneyLaundering': {
        'Exchange Small Bills for Large Bills or Vice  Versa': false,
        'Funnel Account': false,
        Other: false,
        'Suspicion Concerning the Physical Condition of Funds': false,
        'Suspicion Concerning the Source of Funds': false,
        'Suspicion Designation of Beneficiaries, Assignees or Joint Owners':
          false,
        'Suspicion EFT / Wire Transfers': false,
        'Suspicion Exchange of Currencies': false,
        'Suspicious Receipt of Government Payments / Benefits': false,
        'Suspicious Use of Multiple Accounts': false,
        'Suspicious Use of Noncash Monetary Instruments': false,
        'Suspicious Use of Third-Party Transactors (Straw-Man)': false,
        'Trade Based Money Laundering / Black Market Peso Exchange': false,
        'Transaction Out of Pattern for Customer(s)': false,
      },
      '36_typeOfMoneyLaunderingOther': '',
      '37_typeOfIdentificationDocumentation': {
        'Changes Spelling or Arrangement of Name': false,
        'Multiple Individuals with Same or Similar Identities': false,
        Other: false,
        'Provided Questionable or False Documentation': false,
        'Provided Questionable or False Identification': false,
        'Refused or Avoided Request for Documentation': false,
        'Single Individual with Multiple Identities': false,
      },
      '37_typeOfIdentificationDocumentationOther': '',
      '38_typeOfOtherSuspiciousActivities': {
        'Account Takeover': false,
        'Bribery or Gratuity': false,
        'Counterfeit Instruments': false,
        'Elder Financial Exploitation': false,
        'Embezzlement/theft/\ndisappearance of funds': false,
        Forgeries: false,
        'Human Smuggling': false,
        'Human Trafficking': false,
        'Identity Theft': false,
        'Little or No Concern for Product Performance Penalities, Fees, Or Tax Consequences':
          false,
        'Misuse of Position or Self-Dealing': false,
        Other: false,
        'Suspected Public /Private Corruption (Domestic)': false,
        'Suspected public/private corruption (foreign)': false,
        'Suspicious Use of Informal Value Transfer': false,
        'Suspicious Use of Multiple Transaction Locations': false,
        'Transaction with No Apparent Economic, Business, or Lawful Purpose':
          false,
        'Transaction(s) Involving Foreign High Risk Jurisdiction': false,
        'Two or More Individual Working Together': false,
        'Unlicensed or Unregistered MSB': false,
      },
      '38_typeOfOtherSuspiciousActivitiesOther': '',
      '39_disabled': false,
      '39_typeOfInsurance': {
        'Excessive Insurance': false,
        'Excessive or Unusual Cash Borrowing Against Policy / Annuity': false,
        Other: false,
        'Proceeds Sent To or Received from Unrelated  Third Party': false,
        'Suspicious Life Settlement Sales Insurance (e.g. STOLIs, Viaticals)':
          false,
        'Suspicious Termination of Policy or Contract': false,
        'Unclear or No Insurable Interest': false,
      },
      '39_typeOfInsuranceOther': '',
      '40_disabled': false,
      '40_typeOfSecuritiesFuturesOptions': {
        'Insider Trading': false,
        'Market Manipulation': false,
        Misappropriation: false,
        Other: false,
        'Unauthorized Pooling': false,
        'Wash Trading': false,
      },
      '40_typeOfSecuritiesFuturesOptionsOther': '',
      '41_typeOfMortgageFraud': {
        'Application Fraud': false,
        'Appraisal Fraud': false,
        'Foreclosure / Short Sale Fraud': false,
        'Loan Modification Fraud': false,
        'Origination Fraud': false,
        Other: false,
      },
      '41_typeOfMortgageFraudOther': '',
      '42_typeOfCyberEvent': {
        'Against Financial Institution Customer(s)': false,
        'Against Financial Institution(s)': false,
        Other: false,
      },
      '42_typeOfCyberEventOther': '',
      '43_ipAddress': [
        {
          '43_ipAddress': '',
          '43_ipAddressDate': '',
          '43_ipAddressTimestamp': '',
        },
      ],
      '44_cyberEventIndicatorDescription': '',
      '44_cyberEventIndicators': [
        {
          '44_cyberEventIndicatorDate': '',
          '44_cyberEventIndicatorTimestamp': '',
          '44_cyberEventIndicatorValue': '',
          '44_cyberEventIndicators': '',
          '44_cyberEventIndicatorsOther': '',
        },
      ],
      '45_typeOfProductsInvolvedOther': '',
      '45_typesOfProductsInvolved': {
        'Bonds / Notes': false,
        'Commercial Mortgage': false,
        'Commercial Paper': false,
        'Credit Card': false,
        'Debit Card': false,
        'Deposit Account': false,
        'Forex Transactions': false,
        'Futures / Options on Futures': false,
        'Hedge Fund': false,
        'Home Equity Line of Credit': false,
        'Home Equity Loan': false,
        'Insurance / Annuity Products': false,
        'Microcap  Securities': false,
        'Mutual Fund': false,
        'Options of Securities': false,
        'Other (List Below)': false,
        'Prepaid Access': false,
        'Residential Mortgage': false,
        'Security Futures Products': false,
        Stocks: false,
        'Swap, Hybird, or other Derivatives': false,
      },
      '46_typeOfPaymentInstrumentInvolved': {
        'Bank / Cashiers Check': false,
        'Foreign Currency': false,
        'Funds Transfer': false,
        'Gaming Instruments': false,
        'Government  Payment': false,
        'Money Orders': false,
        'Other (List Below)': false,
        'Personal / Business Check': false,
        'Travelers Check': false,
        'U.S. Currency': false,
      },
      '46_typeOfPaymentInstrumentInvolvedOther': '',
      '47_commodityType': [''],
      '48_productInstrumentDescription': [''],
      '49_marketWhereTraded': [''],
      '50_cusipNumber': [''],
    },
    valid: false,
  },
  '5': {
    content: { narrative: '' },
    valid: false,
  },
};

export const INITIAL_SAR: FullSARFilingResponse = {
  activity_type: [],
  alerts: [],
  attachments: [],
  cases: [],
  comments: [],
  comments_count: 0,
  content: INITIAL_SAR_CONTENT,
  created_at: null,
  created_by: { full_name: '' },
  deadline: null,
  deadline_config: null,
  description: '',
  entities: [],
  entity_region: [],
  error_msg: '',
  events: [],
  filing_type: 'Initial report',
  first_alert_created_at: null,
  id: -1,
  is_encrypted: false,
  lock: false,
  name: '',
  org_id: -1,
  queue: null,
  report_type: ReportType.FINCEN_SAR,
  status: SarStatus.PREFILING_VALIDATION_PENDING,
  submitted: false,
  updated_at: null,
};

export const TABS = {
  OVERVIEW: 'overview',
  HOME: 'home',
  FILING_INSTITUTION: 'filing-institution',
  FINANCIAL_INSTITUTION: 'financial-institution',
  SUBJECT: 'subject',
  SUSPICIOUS_ACTIVITY: 'suspicious-activity',
  NARRATIVE: 'narrative',
} as const;

export const NEW_SUBJECT: Subject = {
  type_3: 'individual',
  alternativeName_9: [''],
  addressInformation_11_15: [{}],
  formsOfId_18: [{}],
  phoneNumberValues_20_21: [{}],
  emailAddress_22: [''],
  websiteURL_22a: [''],
  relationships_24: [{ relationship_24: [] }],
  accountNumbers_27: [{ accountNumbers_27: [{}] }],
};

export const NEW_FINANCIAL_INSTITUTION: FinancialInstitutionWhereActivityOccurred =
  {
    typeOfSecuritiesAndFutures_54: [],
    branches_68_74: [{}],
  };

export const NAICS_CODE_OPTIONS = [
  {
    text: 'Accommodation (721)',
    value: 'Accommodation (721)',
  },
  {
    text: 'Accounting, Tax Preparation, Bookkeeping, and Payroll Services (5412)',
    value:
      'Accounting, Tax Preparation, Bookkeeping, and Payroll Services (5412)',
  },
  {
    text: 'Activities Related to Credit Intermediation (5223)',
    value: 'Activities Related to Credit Intermediation (5223)',
  },
  {
    text: 'Activities Related to Real Estate (5313)',
    value: 'Activities Related to Real Estate (5313)',
  },
  {
    text: 'Administration of Economic Programs (926)',
    value: 'Administration of Economic Programs (926)',
  },
  {
    text: 'Administration of Environmental Quality Programs (924)',
    value: 'Administration of Environmental Quality Programs (924)',
  },
  {
    text: 'Administration of Housing Programs, Urban Planning, and Community Development (925)',
    value:
      'Administration of Housing Programs, Urban Planning, and Community Development (925)',
  },
  {
    text: 'Administration of Human Resource Programs (923)',
    value: 'Administration of Human Resource Programs (923)',
  },
  {
    text: 'Administrative and Support Services (561)',
    value: 'Administrative and Support Services (561)',
  },
  {
    text: 'Advertising, Public Relations, and Related Services (5418)',
    value: 'Advertising, Public Relations, and Related Services (5418)',
  },
  {
    text: 'Agencies, Brokerages, and Other Insurance Related Activities (5242)',
    value:
      'Agencies, Brokerages, and Other Insurance Related Activities (5242)',
  },
  {
    text: 'Air Transportation (481)',
    value: 'Air Transportation (481)',
  },
  {
    text: 'Amusement Parks and Arcades (7131)',
    value: 'Amusement Parks and Arcades (7131)',
  },
  {
    text: 'Animal Production (112)',
    value: 'Animal Production (112)',
  },
  {
    text: 'Apparel Manufacturing (315)',
    value: 'Apparel Manufacturing (315)',
  },
  {
    text: 'Apparel, Piece Goods, and Notions Merchant Wholesalers (4243)',
    value: 'Apparel, Piece Goods, and Notions Merchant Wholesalers (4243)',
  },
  {
    text: 'Architectural, Engineering, and Related Services (5413)',
    value: 'Architectural, Engineering, and Related Services (5413)',
  },
  {
    text: 'Automobile Dealers (4411)',
    value: 'Automobile Dealers (4411)',
  },
  {
    text: 'Automotive Equipment Rental and Leasing (5321)',
    value: 'Automotive Equipment Rental and Leasing (5321)',
  },
  {
    text: 'Automotive Parts, Accessories, and Tire Stores (4413)',
    value: 'Automotive Parts, Accessories, and Tire Stores (4413)',
  },
  {
    text: 'Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers (4248)',
    value:
      'Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers (4248)',
  },
  {
    text: 'Beverage and Tobacco Product Manufacturing (312)',
    value: 'Beverage and Tobacco Product Manufacturing (312)',
  },
  {
    text: 'Broadcasting (except Internet) (515)',
    value: 'Broadcasting (except Internet) (515)',
  },
  {
    text: 'Building Material and Garden Equipment and Supplies Dealers (444)',
    value: 'Building Material and Garden Equipment and Supplies Dealers (444)',
  },
  {
    text: 'Business, Professional, Labor, Political, and Similar Organizations (8139)',
    value:
      'Business, Professional, Labor, Political, and Similar Organizations (8139)',
  },
  {
    text: 'Chemical and Allied Products Merchant Wholesalers (4246)',
    value: 'Chemical and Allied Products Merchant Wholesalers (4246)',
  },
  {
    text: 'Chemical Manufacturing (325)',
    value: 'Chemical Manufacturing (325)',
  },
  {
    text: 'Civic and Social Organizations (8134)',
    value: 'Civic and Social Organizations (8134)',
  },
  {
    text: 'Clothing Stores (4481)',
    value: 'Clothing Stores (4481)',
  },
  {
    text: 'Commercial and Industrial Machinery and Equipment Rental and Leasing (5324)',
    value:
      'Commercial and Industrial Machinery and Equipment Rental and Leasing (5324)',
  },
  {
    text: 'Community Care Facilities for the Elderly (6233)',
    value: 'Community Care Facilities for the Elderly (6233)',
  },
  {
    text: 'Computer and Electronic Product Manufacturing (334)',
    value: 'Computer and Electronic Product Manufacturing (334)',
  },
  {
    text: 'Computer Systems Design and Related Services (5415)',
    value: 'Computer Systems Design and Related Services (5415)',
  },
  {
    text: 'Construction of Buildings (236)',
    value: 'Construction of Buildings (236)',
  },
  {
    text: 'Consumer Goods Rental (5322)',
    value: 'Consumer Goods Rental (5322)',
  },
  {
    text: 'Couriers and Express Delivery Services (4921)',
    value: 'Couriers and Express Delivery Services (4921)',
  },
  {
    text: 'Crop Production (111)',
    value: 'Crop Production (111)',
  },
  {
    text: 'Data Processing, Hosting and Related Services (518)',
    value: 'Data Processing, Hosting and Related Services (518)',
  },
  {
    text: 'Depository Credit Intermediation (5221)',
    value: 'Depository Credit Intermediation (5221)',
  },
  {
    text: 'Direct Selling Establishments (4543)',
    value: 'Direct Selling Establishments (4543)',
  },
  {
    text: "Drugs and Druggists' Sundries Merchant Wholesalers (4242)",
    value: "Drugs and Druggists' Sundries Merchant Wholesalers (4242)",
  },
  {
    text: 'Educational Services (611)',
    value: 'Educational Services (611)',
  },
  {
    text: 'Electrical and Electronic Goods Merchant Wholesalers (4236)',
    value: 'Electrical and Electronic Goods Merchant Wholesalers (4236)',
  },
  {
    text: 'Electrical Equipment, Appliance, and Component Manufacturing (335)',
    value: 'Electrical Equipment, Appliance, and Component Manufacturing (335)',
  },
  {
    text: 'Electronic Shopping and MaiI-Order Houses (4541)',
    value: 'Electronic Shopping and MaiI-Order Houses (4541)',
  },
  {
    text: 'Electronics and Appliance Stores (443)',
    value: 'Electronics and Appliance Stores (443)',
  },
  {
    text: 'Executive, Legislative, and Other General Government Support (921)',
    value: 'Executive, Legislative, and Other General Government Support (921)',
  },
  {
    text: 'Fabricated Metal Product Manufacturing (332)',
    value: 'Fabricated Metal Product Manufacturing (332)',
  },
  {
    text: 'Farm Product Raw Material Merchant Wholesalers (4245)',
    value: 'Farm Product Raw Material Merchant Wholesalers (4245)',
  },
  {
    text: 'Fishing, Hunting and Trapping (114)',
    value: 'Fishing, Hunting and Trapping (114)',
  },
  {
    text: 'Food and Beverage Stores (445)',
    value: 'Food and Beverage Stores (445)',
  },
  {
    text: 'Food Manufacturing (311)',
    value: 'Food Manufacturing (311)',
  },
  {
    text: 'Food Services and Drinking Places (722)',
    value: 'Food Services and Drinking Places (722)',
  },
  {
    text: 'Forestry and Logging (113)',
    value: 'Forestry and Logging (113)',
  },
  {
    text: 'Furniture and Home Furnishing Merchant Wholesalers (4232)',
    value: 'Furniture and Home Furnishing Merchant Wholesalers (4232)',
  },
  {
    text: 'Furniture and Home Furnishings Stores (442)',
    value: 'Furniture and Home Furnishings Stores (442)',
  },
  {
    text: 'Furniture and Related Product Manufacturing (337)',
    value: 'Furniture and Related Product Manufacturing (337)',
  },
  {
    text: 'Gambling Industries (7132)',
    value: 'Gambling Industries (7132)',
  },
  {
    text: 'Gasoline Stations (447)',
    value: 'Gasoline Stations (447)',
  },
  {
    text: 'General Medical and Surgical Hospitals (6221)',
    value: 'General Medical and Surgical Hospitals (6221)',
  },
  {
    text: 'General Merchandise Stores (452)',
    value: 'General Merchandise Stores (452)',
  },
  {
    text: 'General Rental Centers (5323)',
    value: 'General Rental Centers (5323)',
  },
  {
    text: 'Grantmaking and Giving Services (8132)',
    value: 'Grantmaking and Giving Services (8132)',
  },
  {
    text: 'Grocery and Related Product Merchant Wholesalers (4244)',
    value: 'Grocery and Related Product Merchant Wholesalers (4244)',
  },
  {
    text: 'Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers (4237) ',
    value:
      'Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers (4237) ',
  },
  {
    text: 'Health and Personal Care Stores (446)',
    value: 'Health and Personal Care Stores (446)',
  },
  {
    text: 'Heavy and Civil Engineering Construction (237)',
    value: 'Heavy and Civil Engineering Construction (237)',
  },
  {
    text: 'Home Health Care Services (6216)',
    value: 'Home Health Care Services (6216)',
  },
  {
    text: 'Insurance and Employee Benefit Funds (5251)',
    value: 'Insurance and Employee Benefit Funds (5251)',
  },
  {
    text: 'Insurance Carriers (5241)',
    value: 'Insurance Carriers (5241)',
  },
  {
    text: 'Jewelry, Luggage, and Leather Goods Stores (4483)',
    value: 'Jewelry, Luggage, and Leather Goods Stores (4483)',
  },
  {
    text: 'Justice, Public Order, and Safety Activities (922)',
    value: 'Justice, Public Order, and Safety Activities (922)',
  },
  {
    text: 'Leather and Allied Product Manufacturing (316)',
    value: 'Leather and Allied Product Manufacturing (316)',
  },
  {
    text: 'Legal Services (5411)',
    value: 'Legal Services (5411)',
  },
  {
    text: 'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works) (533)',
    value:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works) (533)',
  },
  {
    text: 'Lessors of Real Estate (5311)',
    value: 'Lessors of Real Estate (5311)',
  },
  {
    text: 'Local Messengers and Local Delivery (4922)',
    value: 'Local Messengers and Local Delivery (4922)',
  },
  {
    text: 'Lumber and Other Construction Materials Merchant Wholesalers (4233)',
    value:
      'Lumber and Other Construction Materials Merchant Wholesalers (4233)',
  },
  {
    text: 'Machinery Manufacturing (333)',
    value: 'Machinery Manufacturing (333)',
  },
  {
    text: 'Machinery, Equipment, and Supplies Merchant Wholesalers (4238)',
    value: 'Machinery, Equipment, and Supplies Merchant Wholesalers (4238)',
  },
  {
    text: 'Management of Companies and Enterprises (551)',
    value: 'Management of Companies and Enterprises (551)',
  },
  {
    text: 'Management, Scientific, and Technical Consulting Services (5416)',
    value: 'Management, Scientific, and Technical Consulting Services (5416)',
  },
  {
    text: 'Medical and Diagnostic Laboratories (6215)',
    value: 'Medical and Diagnostic Laboratories (6215)',
  },
  {
    text: 'Metal and Mineral (except Petroleum) Merchant Wholesalers (4235)',
    value: 'Metal and Mineral (except Petroleum) Merchant Wholesalers (4235)',
  },
  {
    text: 'Mining (except Oil and Gas) (212)',
    value: 'Mining (except Oil and Gas) (212)',
  },
  {
    text: 'Miscellaneous Durable Goods Merchant Wholesalers (4239)',
    value: 'Miscellaneous Durable Goods Merchant Wholesalers (4239)',
  },
  {
    text: 'Miscellaneous Manufacturing (339)',
    value: 'Miscellaneous Manufacturing (339)',
  },
  {
    text: 'Miscellaneous Nondurable Goods Merchant Wholesalers (4249)',
    value: 'Miscellaneous Nondurable Goods Merchant Wholesalers (4249)',
  },
  {
    text: 'Miscellaneous Store Retailers (453)',
    value: 'Miscellaneous Store Retailers (453)',
  },
  {
    text: 'Monetary Authorities-Central Bank (521)',
    value: 'Monetary Authorities-Central Bank (521)',
  },
  {
    text: 'Mortgage and nonmortgage loan brokers (52231)',
    value: 'Mortgage and nonmortgage loan brokers (52231)',
  },
  {
    text: 'Motion Picture and Sound Recording Industries (512)',
    value: 'Motion Picture and Sound Recording Industries (512)',
  },
  {
    text: 'Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers (4231)',
    value:
      'Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers (4231)',
  },
  {
    text: 'Museums, Historical Sites, and Similar Institutions (712)',
    value: 'Museums, Historical Sites, and Similar Institutions (712)',
  },
  {
    text: 'National Security and International Affairs (928)',
    value: 'National Security and International Affairs (928)',
  },
  {
    text: 'Nondepository Credit Intermediation (5222)',
    value: 'Nondepository Credit Intermediation (5222)',
  },
  {
    text: 'Nonmetallic Mineral Product Manufacturing (327)',
    value: 'Nonmetallic Mineral Product Manufacturing (327)',
  },
  {
    text: 'Nursing Care Facilities (6231)',
    value: 'Nursing Care Facilities (6231)',
  },
  {
    text: 'Offices of Dentists (6212)',
    value: 'Offices of Dentists (6212)',
  },
  {
    text: 'Offices of Other Health Practitioners (6213)',
    value: 'Offices of Other Health Practitioners (6213)',
  },
  {
    text: 'Offices of Physicians (6211)',
    value: 'Offices of Physicians (6211)',
  },
  {
    text: 'Offices of Real Estate Agents and Brokers (5312)',
    value: 'Offices of Real Estate Agents and Brokers (5312)',
  },
  {
    text: 'Oil and Gas Extraction (211)',
    value: 'Oil and Gas Extraction (211)',
  },
  {
    text: 'Other Ambulatory Health Care Services (6219)',
    value: 'Other Ambulatory Health Care Services (6219)',
  },
  {
    text: 'Other Amusement and Recreation Industries (7139)',
    value: 'Other Amusement and Recreation Industries (7139)',
  },
  {
    text: 'Other Financial Investment Activities (5239)',
    value: 'Other Financial Investment Activities (5239)',
  },
  {
    text: 'Other Information Services (519)',
    value: 'Other Information Services (519)',
  },
  {
    text: 'Other Investment Pools and Funds (5259)',
    value: 'Other Investment Pools and Funds (5259)',
  },
  {
    text: 'Other Motor Vehicle Dealers (4412)',
    value: 'Other Motor Vehicle Dealers (4412)',
  },
  {
    text: 'Other Pipeline Transportation (4869)',
    value: 'Other Pipeline Transportation (4869)',
  },
  {
    text: 'Other Professional, Scientific, and Technical Services (5419)',
    value: 'Other Professional, Scientific, and Technical Services (5419)',
  },
  {
    text: 'Other Residential Care Facilities (6239)',
    value: 'Other Residential Care Facilities (6239)',
  },
  {
    text: 'Other Telecommunications (5179)',
    value: 'Other Telecommunications (5179)',
  },
  {
    text: 'Outpatient Care Centers (6214)',
    value: 'Outpatient Care Centers (6214)',
  },
  {
    text: 'Paper and Paper Product Merchant Wholesalers (4241)',
    value: 'Paper and Paper Product Merchant Wholesalers (4241)',
  },
  {
    text: 'Paper Manufacturing (322)',
    value: 'Paper Manufacturing (322)',
  },
  {
    text: 'Performing Arts, Spectator Sports, and Related Industries (711)',
    value: 'Performing Arts, Spectator Sports, and Related Industries (711)',
  },
  {
    text: 'Personal and Laundry Services (812)',
    value: 'Personal and Laundry Services (812)',
  },
  {
    text: 'Petroleum and Coal Products Manufacturing (324)',
    value: 'Petroleum and Coal Products Manufacturing (324)',
  },
  {
    text: 'Petroleum and Petroleum Products Merchant Wholesalers (4247)',
    value: 'Petroleum and Petroleum Products Merchant Wholesalers (4247)',
  },
  {
    text: 'Pipeline Transportation of Crude Oil (4861)',
    value: 'Pipeline Transportation of Crude Oil (4861)',
  },
  {
    text: 'Pipeline Transportation of Natural Gas (4862)',
    value: 'Pipeline Transportation of Natural Gas (4862)',
  },
  {
    text: 'Plastics and Rubber Products Manufacturing (326)',
    value: 'Plastics and Rubber Products Manufacturing (326)',
  },
  {
    text: 'Postal Service (491)',
    value: 'Postal Service (491)',
  },
  {
    text: 'Primary Metal Manufacturing (331)',
    value: 'Primary Metal Manufacturing (331)',
  },
  {
    text: 'Printing and Related Support Activities (323)',
    value: 'Printing and Related Support Activities (323)',
  },
  {
    text: 'Private Households (814)',
    value: 'Private Households (814)',
  },
  {
    text: 'Professional and Commercial Equipment and Supplies Merchant Wholesalers (4234)',
    value:
      'Professional and Commercial Equipment and Supplies Merchant Wholesalers (4234)',
  },
  {
    text: 'Psychiatric and Substance Abuse Hospitals (6222)',
    value: 'Psychiatric and Substance Abuse Hospitals (6222)',
  },
  {
    text: 'Publishing Industries (except Internet) (511)',
    value: 'Publishing Industries (except Internet) (511)',
  },
  {
    text: 'Rail Transportation (482)',
    value: 'Rail Transportation (482)',
  },
  {
    text: 'Religious Organizations (8131)',
    value: 'Religious Organizations (8131)',
  },
  {
    text: 'Repair and Maintenance (811)',
    value: 'Repair and Maintenance (811)',
  },
  {
    text: 'Residential Mental Retardation, Mental Health and Substance Abuse Facilities (6232)',
    value:
      'Residential Mental Retardation, Mental Health and Substance Abuse Facilities (6232)',
  },
  {
    text: 'Satellite Telecommunications (5174)',
    value: 'Satellite Telecommunications (5174)',
  },
  {
    text: 'Scenic and Sightseeing Transportation (487)',
    value: 'Scenic and Sightseeing Transportation (487)',
  },
  {
    text: 'Scientific Research and Development Services (5417)',
    value: 'Scientific Research and Development Services (5417)',
  },
  {
    text: 'Securities and Commodity Contracts Intermediation and Brokerage (5231)',
    value:
      'Securities and Commodity Contracts Intermediation and Brokerage (5231)',
  },
  {
    text: 'Securities and Commodity Exchanges (5232)',
    value: 'Securities and Commodity Exchanges (5232)',
  },
  {
    text: 'Shoe Stores (4482)',
    value: 'Shoe Stores (4482)',
  },
  {
    text: 'Social Advocacy Organizations (8133)',
    value: 'Social Advocacy Organizations (8133)',
  },
  {
    text: 'Social Assistance (624)',
    value: 'Social Assistance (624)',
  },
  {
    text: 'Space Research and Technology (927)',
    value: 'Space Research and Technology (927)',
  },
  {
    text: 'Specialized Design Services (5414)',
    value: 'Specialized Design Services (5414)',
  },
  {
    text: 'Specialty (except Psychiatric and Substance Abuse) Hospitals (6223)',
    value:
      'Specialty (except Psychiatric and Substance Abuse) Hospitals (6223)',
  },
  {
    text: 'Specialty Trade Contractors (238)',
    value: 'Specialty Trade Contractors (238)',
  },
  {
    text: 'Sporting Goods, Hobby, Book, and Music Stores (451)',
    value: 'Sporting Goods, Hobby, Book, and Music Stores (451)',
  },
  {
    text: 'Support Activities for Agriculture and Forestry (115)',
    value: 'Support Activities for Agriculture and Forestry (115)',
  },
  {
    text: 'Support Activities for Mining (213)',
    value: 'Support Activities for Mining (213)',
  },
  {
    text: 'Support Activities for Transportation (488)',
    value: 'Support Activities for Transportation (488)',
  },
  {
    text: 'Textile Mills (313)',
    value: 'Textile Mills (313)',
  },
  {
    text: 'Textile Product Mills (314)',
    value: 'Textile Product Mills (314)',
  },
  {
    text: 'Transit and Ground Passenger Transportation (485)',
    value: 'Transit and Ground Passenger Transportation (485)',
  },
  {
    text: 'Transportation Equipment Manufacturing (336)',
    value: 'Transportation Equipment Manufacturing (336)',
  },
  {
    text: 'Truck Transportation (484)',
    value: 'Truck Transportation (484)',
  },
  {
    text: 'Utilities (221)',
    value: 'Utilities (221)',
  },
  {
    text: 'Vending Machine Operators (4542)',
    value: 'Vending Machine Operators (4542)',
  },
  {
    text: 'Warehousing and Storage (493)',
    value: 'Warehousing and Storage (493)',
  },
  {
    text: 'Waste Management and Remediation Services (562)',
    value: 'Waste Management and Remediation Services (562)',
  },
  {
    text: 'Water Transportation (483)',
    value: 'Water Transportation (483)',
  },
  {
    text: 'Wholesale Electronic Markets and Agents and Brokers (425)',
    value: 'Wholesale Electronic Markets and Agents and Brokers (425)',
  },
  {
    text: 'Wired Telecommunications Carriers (5171)',
    value: 'Wired Telecommunications Carriers (5171)',
  },
  {
    text: 'Wireless Telecommunications Carriers (except Satellite) (5172)',
    value: "Wireless Telecommunications Carriérs (ex'cept Satellite) (5172)",
  },
  {
    text: 'Wood Product Manufacturing (321)',
    value: 'Wood Product Manufacturing (321)',
  },
] as const;

export const STATE_ISSUED_IDS: (SubjectIdTypes | '')[] = [
  'drivers_license',
  'other',
];
export const US_COUNTRY_CODE = 'US';
export const COUNTRY_USMCA = new Set([US_COUNTRY_CODE, 'MX', 'CA']);
export const US_TERRITORIES = new Set(['AS', 'GU', 'MP', 'PR', 'UM', 'VI']);

export const SAR_COUNTRY_OPTIONS = [
  {
    text: 'United States of America',
    value: US_COUNTRY_CODE,
  },
  {
    text: 'Canada',
    value: 'CA',
  },
  {
    text: 'Mexico',
    value: 'MX',
  },
  ...COUNTRY_OPTIONS.filter((country) => !COUNTRY_USMCA.has(country.value)),
];

export const SAR_STATE_OPTIONS = Array.from(COUNTRY_USMCA).reduce(
  (acc, country) => {
    acc[country] = getStateOptions(country);
    return acc;
  },
  {},
);

type FederalRegulatorOption = { text: string; value: FederalRegulators };

const CFTC_OPTION: FederalRegulatorOption = {
  text: 'Commodities Futures Trading Commission (CFTC)',
  value: 'Commodities Futures Trading Commission (CFTC)',
};

const FRB_OPTION: FederalRegulatorOption = {
  text: 'Federal Reserve Board (FRB)',
  value: 'Federal Reserve Board (FRB)',
};

const FDIC_OPTION: FederalRegulatorOption = {
  text: 'Federal Deposit Insurance Corporation (FDIC)',
  value: 'Federal Deposit Insurance Corporation (FDIC)',
};

const FHFA_OPTION: FederalRegulatorOption = {
  text: 'Federal Housing Finance Agency (FHFA)',
  value: 'Federal Housing Finance Agency (FHFA)',
};

const IRS_OPTION: FederalRegulatorOption = {
  text: 'Internal Revenue Service (IRS)',
  value: 'Internal Revenue Service (IRS)',
};

const NCUA_OPTION: FederalRegulatorOption = {
  text: 'National Credit Union Administration (NCUA)',
  value: 'National Credit Union Administration (NCUA)',
};

const OCC_OPTION: FederalRegulatorOption = {
  text: 'Office of the Comptroller of the Currency (OCC)',
  value: 'Office of the Comptroller of the Currency (OCC)',
};

const SEC_OPTION: FederalRegulatorOption = {
  text: 'Securities and Exchange Commission (SEC)',
  value: 'Securities and Exchange Commission (SEC)',
};

const NOT_APPLICABLE_OPTION: FederalRegulatorOption = {
  text: 'Not applicable',
  value: 'Not Applicable',
};

const ALL_FEDERAL_REGULATOR_OPTIONS = [
  CFTC_OPTION,
  FDIC_OPTION,
  FRB_OPTION,
  FHFA_OPTION,
  IRS_OPTION,
  NCUA_OPTION,
  OCC_OPTION,
  SEC_OPTION,
  NOT_APPLICABLE_OPTION,
];

export const FILING_PRIMARY_FEDERAL_REGULATOR_OPTIONS_BY_TYPE: Record<
  FilingInstitutionTypes,
  FederalRegulatorOption[]
> = {
  'Casino/Card club': [IRS_OPTION],
  'Depository institution': [
    FRB_OPTION,
    FDIC_OPTION,
    IRS_OPTION,
    NCUA_OPTION,
    OCC_OPTION,
  ],
  'Insurance company': [IRS_OPTION],
  'MSB (Money Service Business)': [IRS_OPTION],
  'Securities/Futures': [CFTC_OPTION, SEC_OPTION],
  'Loan or Finance Company': ALL_FEDERAL_REGULATOR_OPTIONS,
  'Housing GSE (Government Sponsored Enterprise)':
    ALL_FEDERAL_REGULATOR_OPTIONS,
  Other: ALL_FEDERAL_REGULATOR_OPTIONS,
};

export const FINANCIAL_PRIMARY_FEDERAL_REGULATOR_OPTIONS_BY_TYPE: Record<
  FinancialInstitutionTypes,
  FederalRegulatorOption[]
> = {
  ...FILING_PRIMARY_FEDERAL_REGULATOR_OPTIONS_BY_TYPE,
  'Casino / Card Club': [IRS_OPTION],
};

export const RELATIONS_WITH_STATUS = new Set([
  'Director',
  'Employee',
  'Officer',
  'Owner or Controlling Shareholder',
]);

export const NARRATIVE_TAB_KEYS = {
  CONTENT: 'CONTENT',
  AUDIT_TRAIL: 'AUDIT_TRAIL',
};

export const DEFAULT_SAR_QUEUES_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'title',
    type: 'label',
    label: 'Queue name',
  },
  {
    key: 'description',
    type: 'text',
    label: 'Description',
  },
  { key: 'open_sars', type: 'label', label: 'Open SARs', sortable: false },
  { key: 'teams', type: 'teams', label: 'Teams', sortable: false },
];

export const DEFAULT_SAR_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'id',
    type: 'text',
    label: 'ID',
  },
  {
    key: 'name',
    type: 'text',
    label: 'File name',
  },
  { key: 'created_at', type: 'datetime', label: 'Created at' },
  {
    key: 'created_by',
    type: 'text',
    label: 'Created by',
    sortable: false,
  },
  {
    key: 'reviewer',
    label: 'Reviewer',
    type: 'text',
    sortable: false,
  },
  {
    key: 'deadline',
    label: 'Deadline',
    type: 'text',
    sortable: true,
  },
  { key: 'updated_at', type: 'datetime', label: 'Updated at' },
  { key: 'filing_type', type: 'label', label: 'Filing type', sortable: false },
  {
    key: 'first_alert_created_at',
    type: 'datetime',
    label: 'First alert created on',
  },
  {
    key: 'case.title',
    type: 'text',
    label: 'Case title',
    sortable: false,
  },
  {
    key: 'case.created_at',
    type: 'datetime',
    label: 'Case created on',
    sortable: false,
  },
  {
    key: 'entities',
    type: 'list',
    label: 'Associated Entities',
    sortable: false,
  },
  {
    key: 'status',
    type: 'label',
    label: 'Status',
  },
  { key: 'submitted', type: 'text', label: 'Submitted', sortable: false },
  {
    key: 'bsa_tracking_id',
    type: 'text',
    label: 'BSA tracking ID',
    sortable: false,
  },
  { key: 'bsa_id', type: 'text', label: 'BSA ID', sortable: false },
  { key: 'filed_at', type: 'datetime', label: 'Filed on' },
  { key: 'report_type', type: 'label', label: 'Report Type' },
  { key: 'queue', type: 'label', label: 'Queue' },
];

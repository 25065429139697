import {
  ListFinCEN314aScansPayload,
  ListMatchlistsPayload,
} from 'app/modules/lists/requests';

export const BASE = ['lists'] as const;
export const MATCHLIST_BASE = [...BASE, 'matchlists'] as const;
export const FINCEN_314A_BASE = [...BASE, 'fincen-314a'] as const;

export const LIST_QUERY_KEYS = {
  getFetchMatchlistsQueryKey: (body: ListMatchlistsPayload) =>
    [...MATCHLIST_BASE, 'list', body] as const,
  getGetAlertFinCEN314aScanResultQueryKey: (alertId: number) =>
    [...FINCEN_314A_BASE, 'get', 'scan-result', alertId] as const,
  getListFinCEN314aPersonScansQueryKey: (body?: ListFinCEN314aScansPayload) =>
    [...FINCEN_314A_BASE, 'list', 'person', ...(body ? [body] : [])] as const,
  getListFinCEN314aBusinessScansQueryKey: (body?: ListFinCEN314aScansPayload) =>
    [...FINCEN_314A_BASE, 'list', 'business', ...(body ? [body] : [])] as const,
};
